import { useCallback, useEffect } from 'react'

import { trackPageView, trackSelfDescribingEvent } from '@snowplow/browser-tracker'
import { useNavigate } from 'react-router'

import Page from 'app/layout/Page'
import { DEFAULT_INVITATION_TIME } from 'app/lib/constants'
import { organizationAddPlan, organizationList } from 'app/lib/routes'
import { todayWithTime, toISOdate } from 'app/lib/utils/date'
import { getSnowplowSchema } from 'app/lib/utils/helpers'
import { generateOrganizationAttributes } from 'app/lib/utils/organization'
import { OrganizationAttributes } from 'app/models/Organization'
import {
  useAddOrganizationAddressMutation,
  useAddOrganizationMutation,
  useUpdateOrganizationContentTagsMutation,
} from 'app/redux/scribeApi'

import { OrganizationForm } from './form/OrganizationForm'
import { CreateOrganizationFormData, createOrganizationSchema } from './form/schemas'

export const CreateOrganizationPage: React.FC = () => {
  const navigate = useNavigate()

  const [addOrganization, { isLoading: isOrgLoading }] = useAddOrganizationMutation()
  const [addOrganizationAddress, { isLoading: isAddressLoading }] =
    useAddOrganizationAddressMutation()
  const [updateOrganizationContentTags, { isLoading: isContentTagLoading }] =
    useUpdateOrganizationContentTagsMutation()

  const isLoading = isOrgLoading || isAddressLoading || isContentTagLoading

  const navigateToOrganizationList = useCallback(() => {
    navigate(organizationList.get())
  }, [navigate])

  const submitAddOrganization = useCallback(
    async (formData: CreateOrganizationFormData) => {
      try {
        const { streetAddress, suite, postalCode, city, province, ...organizationFormData } =
          formData

        const response = await addOrganization({
          body: {
            data: {
              type: 'organization',
              attributes: {
                ...(generateOrganizationAttributes(organizationFormData) as OrganizationAttributes),
                billingStartDate: toISOdate(organizationFormData.billingStartDate),
              },
            },
          },
        })
          .unwrap()
          .catch(() => console.error('Error on organization creation'))

        const organizationId = response?.id
        if (!organizationId) return

        addOrganizationAddress({
          organizationId,
          body: {
            data: {
              type: 'organization_address',
              attributes: {
                streetAddress,
                suite,
                postalCode,
                city,
                adminAreaIsoCode: province,
              },
            },
          },
        })

        updateOrganizationContentTags({
          organizationId,
          body: {
            names: ['basic'],
          },
        })

        navigate(organizationAddPlan.get(organizationId))
      } finally {
        trackSelfDescribingEvent({
          event: {
            schema: getSnowplowSchema('button_click', '1-0-0'),
            data: {
              button_value: `Submit Create Organization Form on Presto`,
            },
          },
        })
      }
    },
    [navigate, addOrganization, addOrganizationAddress, updateOrganizationContentTags],
  )

  useEffect(() => {
    trackPageView({ title: 'organization-create-page' })
  }, [])

  return (
    <Page isLoading={isLoading}>
      <OrganizationForm
        onCancel={navigateToOrganizationList}
        onSubmit={submitAddOrganization}
        validationSchema={createOrganizationSchema}
        initialValues={{ invitationEmailTime: todayWithTime(DEFAULT_INVITATION_TIME) }}
        isLoading={isLoading}
      />
    </Page>
  )
}
